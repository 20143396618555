import React, { Component } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Button from '../inputs/Button';
import ManageRatings from './ManageRatings';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { LineChart, Line, BarChart, Bar, YAxis } from 'recharts';
import { rating_fields } from './rating_fields';
import MultipleLinesChart from './MultipleLinesChart';

const styles = theme => ({
  chartContainer: {
    width: '174px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  line: {
    height: '144px',
    width: '1px',
    background: '#e0e2e4',
  },
  toolTip: {
    fontSize: '12px',
  },
});

class Review extends Component {
  state = {
    isModalOpen: false,
    isAllRatingsModalOpen: false,
  };

  openModal = () => this.setState({ isModalOpen: true });
  openAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });
  closeAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: false });

  createMarkup(data) {
    return { __html: data };
  }

  getLines = () => {
    const { classes } = this.props;
    const lines = rating_fields.filter(item => item.name !== 'overall');
    return lines.map(item => (
      <div key={item.name} className="d-flex flex-column align-items-center">
        <div className={classes.line}></div>
        <Tooltip
          title={item.label}
          aria-label={item.label}
          TransitionComponent={Zoom}
          placement="left-end"
          classes={{ tooltip: classes.toolTip }}
        >
          <div className="mt-1" dangerouslySetInnerHTML={this.createMarkup(item.svg)} />
        </Tooltip>
      </div>
    ));
  };

  getLineChartValues = rate => {
    if (rate) {
      const linesChart = rating_fields.filter(item => item.name !== 'overall');
      return linesChart.map(item => {
        const currentRate = rate[item.name] ? rate[item.name] : 0;
        return { ...item, rate: currentRate };
      });
    }
  };

  getoverallValue = rate => {
    if (rate) {
      const overall = rate.overall ? (rate.overall / 5) * 100 : 0;
      const other = 100 - overall;
      return [{ overall, other }];
    }
  };

  render() {
    const { isModalOpen, isAllRatingsModalOpen } = this.state;
    const {
      title,
      proposal,
      edit,
      onSave,
      rate,
      classes,
      allRates,
      secretary,
    } = this.props;
    const lines = this.getLines();
    const lineChartValues = this.getLineChartValues(rate);
    const lineChartValuesAll = allRates ? this.getLineChartValues(allRates.rate) : null;
    const overallValue = this.getoverallValue(rate);
    const overallValueAll = allRates ? this.getoverallValue(allRates.rate) : null;

    return (
      <div>
        <div className="d-flex align-items-center justify-content-between">
          {title && <h5 className="mb-0">{title}</h5>}

          <div className="d-flex flex-column align-items-end">
            {edit && (
              <Button style={{ padding: '5px' }} onClick={this.openModal} size="sm">
                <span className="d-flex align-items-center">
                  <span className="mr-1">Manage </span>
                  <SettingsIcon fontSize="small" />
                </span>
              </Button>
            )}
            {secretary && !!allRates.userVoted && (
              <Button style={{ padding: 0 }} onClick={this.openAllRatingsModal} size="sm">
                <span className="d-flex align-items-center">
                  <span className="mr-1">All ratings </span>
                  <RemoveRedEyeIcon fontSize="small" />
                </span>
              </Button>
            )}
          </div>
        </div>

        {secretary && allRates && (
          <div className="xs">{`(${allRates.userVoted}/${allRates.users} reviewers)`}</div>
        )}

        {lineChartValues.length > 0 && (
          <div>
            <div className="d-flex mt-2 position-relative">
              <div className="pl-1 pr-3 d-flex flex-column align-items-center">
                <div className="d-flex">
                  {secretary && allRates && (
                    <BarChart
                      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                      width={5}
                      height={144}
                      data={overallValueAll}
                    >
                      <Bar dataKey="overall" stackId="a" fill="#9d9d9d" />
                      <Bar dataKey="other" stackId="a" fill="#ececec" />
                    </BarChart>
                  )}
                  <BarChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    width={secretary && allRates ? 5 : 10}
                    height={144}
                    data={overallValue}
                  >
                    <Bar dataKey="overall" stackId="a" fill="#3f51b5" />
                    <Bar dataKey="other" stackId="a" fill="#ececec" />
                  </BarChart>
                </div>

                <Tooltip
                  title={rating_fields[0].label}
                  aria-label={rating_fields[0].label}
                  TransitionComponent={Zoom}
                  placement="left-end"
                  classes={{ tooltip: classes.toolTip }}
                >
                  <div
                    className="mt-1"
                    dangerouslySetInnerHTML={this.createMarkup(rating_fields[0].svg)}
                  />
                </Tooltip>
              </div>

              <div className={classes.chartContainer}>
                {lines}
                {secretary && allRates && (
                  <div className="position-absolute">
                    <LineChart width={174} height={144} data={lineChartValuesAll}>
                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="rate"
                        stroke="#9d9d9d"
                        strokeWidth="4"
                      />
                      <YAxis type="number" domain={[0, 5]} hide={true} />
                    </LineChart>
                  </div>
                )}
                <div className="position-absolute">
                  <LineChart width={174} height={144} data={lineChartValues}>
                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="rate"
                      stroke="#3f51b5"
                      strokeWidth="4"
                    />
                    <YAxis type="number" domain={[0, 5]} hide={true} />
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
        )}
        {edit && (
          <ManageRatings
            title={title}
            close={this.closeModal}
            isOpen={isModalOpen}
            proposal={proposal}
            fields={rating_fields}
            onSave={onSave}
            proposalPrivateRate={rate}
            secretary={secretary}
            proposalAverageRate={allRates.rate}
          />
        )}

        {/* All Ratings Modal */}
        {secretary && isAllRatingsModalOpen && (
          <MultipleLinesChart
            close={this.closeAllRatingsModal}
            isOpen={isAllRatingsModalOpen}
            allRates={allRates}
            proposal={proposal}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Review);
